<template>
  <div class="item_list">
    <van-nav-bar
      title="派送"
      left-arrow
      fixed
      placeholder
      safe-area-inset-top
      @click-left="navBack"
    />
    <div class="mt-10">
      <item-card-goods
        v-for="(item, index) in detail.goods"
        :key="index"
        :goods="item"
        :price="item.goods_price"
        :num="item.goods_num"
        :hidestore="true"
      />
    </div>
    <van-cell-group class="mt-10">
      <van-field label="订单号:" :value="detail.order_sn" readonly />
      <van-field label="收货人：" :value="detail.consignee" readonly />
      <van-field
        label="收货地址："
        rows="1"
        autosize
        :value="`${detail.region} ${detail.address}`"
        readonly
      />
      <van-field
        label="用户备注："
        rows="2"
        autosize
        :value="detail.user_note"
        type="textarea"
        readonly
      />
      <van-field
        label="平台备注："
        rows="2"
        autosize
        :value="detail.info.note"
        type="textarea"
        readonly
      />
    </van-cell-group>
    <van-form class="mt-10" @submit="onSubmit">
      <van-field
        v-model="username"
        name="sender"
        label="派送人:"
        placeholder="请输入派送人"
        :rules="[{ required: true }]"
        :readonly="!editable"
      />
      <van-field
        v-model="userphone"
        name="sender_phone"
        label="电话："
        placeholder="请输入电话"
        :rules="[{ required: true }, { pattern, message: '电话不合法' }]"
        :readonly="!editable"
      />
      <div v-if="editable" class="m-16 mt-30">
        <van-button
          :loading="isLogining"
          round
          block
          type="primary"
          native-type="submit"
        >
          提交
        </van-button>
      </div>
    </van-form>
  </div>
</template>

<script>
import {
  List,
  Cell,
  CellGroup,
  Field,
  Form,
  Toast,
  Dialog,
  NavBar,
  Button
} from 'vant'
import ItemCardGoods from '../../../components/item-card-goods'
// eslint-disable-next-line no-unused-vars
import { outSend } from '@/api/delivery'
export default {
  components: {
    [NavBar.name]: NavBar,
    [List.name]: List,
    [Cell.name]: Cell,
    [Field.name]: Field,
    [Form.name]: Form,
    [Toast.name]: Toast,
    [Button.name]: Button,
    [Dialog.Component.name]: Dialog.Component,
    [CellGroup.name]: CellGroup,
    [ItemCardGoods.name]: ItemCardGoods
  },
  props: {
    id: {
      type: String,
      default: '0'
    },
    detail: {
      type: Object,
      default: () => {}
    },
    editable: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      transitionName: 'van-slide-right',
      username: '-',
      userphone: '-',
      isLogining: false,
      ordernumber: '20201103845384897',
      consignee: '张三',
      address: '广西南宁XX区XX县XXXX村XXXXX号',
      comment: '给我安排帅哥配',
      pattern: /^1[3456789]\d{9}$/
    }
  },
  computed: {},
  created() {},
  mounted() {
    this.initdata()
  },
  methods: {
    initdata() {
      if (this.editable) {
        this.username = ''
        this.userphone = ''
      } else {
        this.username = this.detail.sender
        this.userphone = this.detail.sender_phone
      }
      console.log(this.detail)
    },
    toggleFilterModal() {},
    navBack() {
      this.$router.go(-1)
    },
    async onSubmit(values) {
      this.isLogining = true
      try {
        values = { ...values, ...{ id: parseInt(this.id) } }
        // console.log('submit', values)
        // const loading = this.$loading()
        await this.applyOutSend(values)
      } catch (err) {
        console.log(err)
        if (err.message) Toast(err.message)
      } finally {
        this.isLogining = false
      }
    },
    async applyOutSend(apdata) {
      console.log(apdata)
      const data = await outSend(apdata)
      console.log(data)
      if (data.status === 1) {
        console.log('成功')
        this.detail.status = 1
        Dialog.alert({
          title: '提交成功',
          message: '派送信息已成功提交'
        }).then(() => {
          this.navBack()
        })
      } else {
        throw data
      }
    }
  }
}
</script>

<style lang="less" scoped>
@import '@/style/global.less';
.fixheight {
  // height: calc(100vh - 44px - 44px);
  overflow-x: hidden;
  overflow-y: scroll;
}

.mt-10 {
  margin-top: 10px;
}
.m-16 {
  margin: 16px;
}
.mt-30 {
  margin-top: 30px;
}

.item_list {
  height: auto;
  background-color: #f5f5f5;
}

.item_list .van-tab--disabled {
  color: #000000;
}

.class_datetimepicker {
  border-radius: 5px;
}
</style>
